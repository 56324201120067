@import "../../../styles/constants.scss";

.cleaning-unit-flow {
  min-width: calc(512px + var(--space-unit-xxl));
  max-width: calc(512px + var(--space-unit-xxl));
  min-height: 234px;
  max-height: 234px;
  border-color: var(--color-black) !important;

  &.warn {
    border-color: var(--color-warning-warning100) !important;
  }

  &.error {
    border-color: var(--color-alert-alert100) !important;
  }

  @media (max-width: $mobile-breakpoint-3) {
    min-width: unset;
    max-width: unset;
    max-height: unset;
    width: 100%;
  }

  &-content {
    display: flex;
    gap: var(--space-unit-xl);
    overflow: hidden;
    @media (max-width: $mobile-breakpoint-3) {
      flex-direction: column;
    }
  }

  &-status {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: var(--space-unit-md);
    max-height: 180px;

    .cleaning-unit-flow-status-info-wrapper {
      display: flex;
      gap: var(--space-unit-lg);
      flex: 1;
      align-items: center;
      justify-content: center;
    }

    &-info {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: var(--space-unit-md);

      .cufs-info-mode-label {
        color: var(--color-grey-grey100);
        margin-right: var(--space-unit-xxs);
      }
    }

    .cleaning-unit-flow-badges-wrapper {
      display: grid;
      grid-template-columns: 24px 24px;
      gap: var(--space-unit-xl);
      justify-content: center;

      .cleaning-unit-flow-badge {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: var(--space-unit-xxs);
        color: var(--color-grey-grey100) "";
      }
    }
  }

  &-description {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 180px;

    .cuf-progress-bar {
      width: 214px;
    }

    .cuf-step-common {
      height: 100%;
      display: flex;
      flex-direction: column;
      gap: var(--space-unit-md);
      align-items: center;

      &-center {
        justify-content: center;
      }

      &-content {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: var(--space-unit-md);
      }
    }

    .cuf-step-cleaning-start-mode {
      height: 100%;

      .cuf-csm-content {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: var(--space-unit-lg);
        align-items: center;
        justify-content: center;
      }

      .cuf-csm-actions {
        display: flex;
      }
    }

    .cuf-content {
      display: flex;
      flex: 1;
      flex-direction: column;
      gap: var(--space-unit-md);
      align-items: center;

      &.centered {
        justify-content: center;
      }
    }

    .cuf-actions {
      display: flex;
      flex-direction: row;
    }

    .cuf-info {
      height: 100%;

      .cuf-info-wrapper {
        display: flex;
        flex-direction: column;
        height: 100%;

        transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
      }

      .label-for {
        cursor: pointer;
      }

      .pointless-button {
        pointer-events: none;
      }

      .hidden-checkbox {
        display: none;
      }

      .cuf-info-wrapper:nth-child(2) {
        opacity: 1;
      }

      .cuf-info-wrapper:nth-child(3) {
        opacity: 0;
      }

      input:checked ~ .cuf-info-wrapper:nth-child(2) {
        opacity: 0;
        transform: translateY(-100%);
      }

      input:checked ~ .cuf-info-wrapper:nth-child(3) {
        opacity: 1;
        transform: translateY(-100%);
      }
    }
  }

  .info-title {
    color: var(--color-grey-grey100);
  }
}

.logo-array-wrapper {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;

  .logo-array {
    border: 1px solid var(--color-white);
    border-radius: 50%;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 8px;
    box-sizing: border-box;
    flex-shrink: 0;
    height: 24px;
    width: 24px;

    &:not(:first-child) {
      margin-right: -6px;
    }

    &--ellipsis {
      padding: var(--space-unit-xxs);
    }

    &--ellipsis,
    &--fallback {
      background-color: var(--color-grey-grey50);
    }
  }
}
