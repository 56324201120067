@import "../../styles/constants.scss";
@import "../../styles/animations.scss";

.device-card {
  background-color: var(--color-white);
  border-radius: 4px !important;
  box-sizing: border-box;
  justify-content: space-between;
  min-width: 195px;
  max-width: 195px;
  position: relative;
  cursor: pointer;

  @media (max-width: $mobile-breakpoint-3) {
    max-width: unset;
    width: 100%;
  }

  &.real {
    opacity: 0;
    animation: fade-in 0.5s forwards;
    border-color: var(--color-grey-greenish);
  }

  &:hover {
    z-index: 1;
    border-color: var(--color-black);
  }

  &.warning {
    border-color: var(--color-warning-warning50);
  }
  &.error {
    border-color: var(--color-alert-alert50);
  }
  &.loading {
    background-color: var(--color-grey-grey25);
    opacity: 0;
    min-height: 256px;
    animation: pulse 3s infinite, fade-in 0.5s 0.5s forwards;
  }

  &.beerdrive {
    max-width: calc(316px + var(--space-unit-xxl));
    min-width: calc(316px + var(--space-unit-xxl));

    @media (max-width: $mobile-breakpoint-3) {
      max-width: unset;
      min-width: 256px;
    }

    .device-card-body {
      .tank {
        flex-grow: 1;
      }

      .pressure-chamber-info {
        flex-basis: 30%;
        flex-direction: column;
        margin-left: var(--space-unit-md);
        min-width: 0;

        @media (max-width: $mobile-breakpoint-3) {
          margin-left: 0;
          margin-top: var(--space-unit-md);
        }
      }
    }
  }

  .device-card-body {
    display: flex;
    flex-direction: column;

    .pressure-chamber-info {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .pressure-chamber-title {
      margin-bottom: var(--space-unit-lg);
    }
  }

  .optional-buy-button {
    min-width: 180px;
    margin-top: var(--space-unit-xs);
  }

  .devicecard-editbutton {
    border: 1px solid var(--color-white);
    border-bottom: 0;
    border-radius: 4px 0px 0px 0px;
    border-right: 0;
    bottom: 0;
    position: absolute;
    right: 0;
  }
}
