@import "../../styles/constants.scss";
@import "../../styles/animations.scss";

.pressurechamber-info-wrapper {
  margin-bottom: var(--space-unit-lg);
  margin-top: var(--space-unit-md);
}

.modal-divider {
  margin: 0px var(--space-unit-lg);
  width: auto !important;
}

.pressurechamber-brand-dropdown {
  width: 100%;
}
