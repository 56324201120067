@import "../../styles/constants.scss";
@import "../../styles/animations.scss";

.installation-page {
  display: flex;
  flex-direction: column;

  .header {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    margin-top: var(--space-unit-lg);
    margin-bottom: var(--space-unit-lg);

    > button {
      margin-left: var(--space-unit-xs);
      padding: 0;
    }
  }

  .installation-page-status {
    display: flex;
    flex: 1 1;
    background-color: var(--color-white);
    margin-bottom: var(--space-unit-lg);
    border-radius: 10px;
    justify-content: space-between;

    @media (max-width: $mobile-breakpoint-1) {
      flex-wrap: wrap;
    }

    .installation-page-infoicon {
      box-sizing: border-box;
      padding-left: var(--space-unit-md);
      padding-right: var(--space-unit-md);
      // each element has var(--space-unit-xxl) between each other
      width: calc(25% - (3 * var(--space-unit-xxl) / 4));

      @media (max-width: $mobile-breakpoint-1) {
        width: calc(50% - (var(--space-unit-xxl) / 2));
      }

      &:first-child,
      &:nth-child(2) {
        @media (max-width: $mobile-breakpoint-1) {
          margin-bottom: var(--space-unit-lg);
        }
      }
    }

    .overview-badge {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-right: var(--space-unit-xxl);
      margin-bottom: var(--space-unit-md);
      margin-top: var(--space-unit-md);

      .name {
        margin-left: 12px;
        color: var(--color-grey-grey100);
        font-weight: 700;
        margin-right: 12px;
      }
    }
  }

  .devicesRow {
    &.loading {
      display: grid;
      grid-gap: var(--space-unit-xxl);
      grid-template-columns: repeat(auto-fill, minmax(256px, 1fr));
      margin-bottom: var(--space-unit-xxl);
    }

    .outlet-panels {
      width: 100%;

      .outlet-panel {
        & > div:nth-child(2) {
          & > div {
            flex-direction: column; // hacky way of accessing expandable panel's internal element
          }
        }
      }

      .outlet-panel-tags {
        display: flex;

        @media (max-width: $mobile-breakpoint-2) {
          justify-content: space-between;
          margin-top: var(--space-unit-md);
          width: 106%; // overlap expandable panel's arrow icon's vertical space
        }

        .outlet-panel-tag {
          align-items: center;
          display: flex;
          width: 50px;

          &:last-child {
            @media (max-width: $mobile-breakpoint-2) {
              width: auto;
            }
          }

          @media (max-width: $mobile-breakpoint-2) {
            width: 25%;
          }

          @media (max-width: $mobile-breakpoint-3) {
            width: 46px;
          }

          &:not(:last-child) {
            margin-right: var(--space-unit-xxl);
          }

          .outlet-panel-tag-icon {
            margin-right: var(--space-unit-xs);
          }
        }
      }
    }
  }

  .installation-page-installation {
    display: flex;
    flex-wrap: wrap;
    gap: var(--space-unit-xxl);
    margin-bottom: var(--space-unit-xxl);
  }
}

.installation-page {
  .devicesRow {
    .product-beverage-details-sidebar {
      position: fixed;
      max-height: 100vh;
      width: 432px;
      overflow: auto;

      @media (max-width: $mobile-breakpoint-3) {
        width: 100%;
        height: calc(100% - var(--space-unit-xxxl));
        margin-top: var(--space-unit-xxxl);
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
      }

      &-fixed-info {
        position: sticky;
        top: 0;
        left: 0;
        right: 0;
        background: var(--color-white);
        padding: var(--space-unit-md) var(--space-unit-lg);
        align-items: center;
        grid-template-columns: 32px 1fr 16px;
        grid-gap: 8px;
        gap: 8px;
        box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 8px;
        display: none;
        z-index: 10;

        &--show {
          display: grid;
        }

        .pbds-close-button {
          margin: 0;
          align-self: center;
        }

        .pbds-beverage-name {
        }
      }

      &-close-button {
        padding: var(--space-unit-lg);
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: sticky;
        top: 0;
        width: 100%;
        box-sizing: border-box;
        border-bottom: 1px solid var(--color-grey-grey50);
        background-color: white;
        z-index: 1;
      }

      &-body {
        align-items: center;
        display: flex;
        flex-direction: column;
        text-align: center;
        padding-bottom: var(--space-unit-xxl);
        position: relative;

        .product-beverage-details-sidebar-body-padding {
          align-items: center;
          display: flex;
          flex-direction: column;
          text-align: center;
          padding: 0 var(--space-unit-lg);
          width: 100%;
          box-sizing: border-box;
        }

        .keg-wrapper {
          margin-top: var(--space-unit-xxl);
          margin-bottom: var(--space-unit-xxl);
          transform: scale3d(1.3, 1.3, 1);

          &.tank-wrapper {
            width: 260px;
          }
        }

        .change-brand-option {
          margin-top: var(--space-unit-xs);
          color: var(--color-grey-grey100);
          cursor: pointer;
        }

        .tap-position-wrapper {
          display: flex;
          margin-top: var(--space-unit-lg);

          .tap-position-badge {
            background-color: var(--color-grey-greenish);
            border-radius: 40px;
            padding: var(--space-unit-xxs) var(--space-unit-lg);
          }
        }

        .product-beverage-data-divider {
          margin: var(--space-unit-xl) 0;
          background-color: var(--color-grey-grey50);
        }

        .tag-badge {
          width: calc(100% - var(--space-unit-xxl));
          margin: var(--space-unit-lg);
          margin-bottom: 0;
          padding: var(--space-unit-lg);
          border: 1px solid var(--color-grey-greenish);
          border-radius: 10px;

          &.warning {
            border-color: var(--color-warning-warning50);
            .tag-title {
              color: var(--color-warning-warning100);
            }
          }

          &.error {
            border-color: var(--color-alert-alert50);
            color: var(--color-alert-alert100);
          }

          .tag-title {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: var(--space-unit-sm);
            color: var(--color-black);

            .tag-title-icon {
              margin-right: var(--space-unit-xs);
            }
          }

          .tag-description {
            color: var(--color-black);
          }
        }
      }

      .error-banner {
        background-color: var(--color-alert-alert100);
        padding: var(--space-unit-lg);
        color: var(--color-white);
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        box-sizing: border-box;

        &.open {
          background-color: var(--color-grey-greenish);
          color: var(--color-black);
        }

        .error-banner-title {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .error-banner-icon {
          margin-right: var(--space-unit-xs);
        }

        .error-banner-description {
          margin-top: var(--space-unit-lg);
        }
      }
    }
  }
}
