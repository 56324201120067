@import "../../styles/constants.scss";
@import "../../styles/animations.scss";

.pressurechamber-badges {
  display: flex;
  justify-content: space-around;
  margin-top: var(--space-unit-lg);
  width: 100%;

  .kegDescription {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-left: var(--space-unit-lg);
    max-width: 70%;

    .badge-row {
      display: flex;
      flex-direction: row;

      .notif-container {
        .icon {
          background-color: white;
          border-radius: 50%;
        }

        .tooltip:hover {
          .icon {
            box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.18);
          }
        }
      }
    }
  }

  .notif-container {
    min-width: 24px;

    .icon {
      width: 24px;
      height: 24px;
      font-size: 24px;
      margin: 0 4px;
      margin-top: var(--space-unit-md);
      position: relative;
    }

    .notif-counter {
      position: absolute;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background-color: var(--color-black);
      color: var(--color-white);
      font-size: 10px;
      line-height: 10px;
      text-align: center;
      padding: 1px;
      right: -2px;
      box-sizing: border-box;
      top: -2px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
  }
}

.pressurechamber-badge-description p {
  line-height: inherit;
}

.tooltip-title {
  align-items: center;
  display: flex;
  justify-content: flex-start;

  &-icon {
    margin-right: var(--space-unit-xs);
  }
}

.tooltip-divider {
  margin: var(--space-unit-sm) 0 var(--space-unit-md);
}

.tooltip-faulty {
  align-items: center;

  .tooltip-divider {
    margin-bottom: var(--space-unit-xs);
  }

  .tooltip-sensor-warning {
    display: flex;
    justify-content: center;
  }
}

.pressurechamber-badge {
  border: 1px solid transparent;
  border-radius: 50%;
  stroke: none;

  &.success {
    fill: var(--color-success-success100);
  }

  &.warning {
    fill: var(--color-warning-warning100);
  }

  &.danger {
    fill: var(--color-alert-alert100);
  }

  &.disabled {
    fill: var(--color-grey-grey50);
  }

  &.normal {
    fill: var(--color-black);
  }

  &--conection {
    &.success {
      fill: var(--color-white);
      border-color: var(--color-success-success100);
      background-color: var(--color-success-success100);
    }

    &.warning {
      fill: var(--color-white);
      border-color: var(--color-warning-warning100);
      background-color: var(--color-warning-warning100);
    }

    &.danger {
      fill: var(--color-white);
      border-color: var(--color-alert-alert100);
      background-color: var(--color-alert-alert100);
    }

    &.disabled {
      fill: var(--color-white);
      border-color: var(--color-grey-grey50);
      background-color: var(--color-grey-grey50);
    }

    &.normal {
      fill: var(--color-white);
      border-color: var(--color-black);
      background-color: var(--color-black);
    }
  }
}
