.brand-recommendation-wrapper {
  padding-left: var(--space-unit-lg);

  .brand-recommendation {
    margin-top: var(--space-unit-lg);
    width: 100%;

    .brand-recommendation-item {
      display: flex;
      flex-direction: column;
      border: 1px solid var(--color-grey-grey25);
      border-radius: 10px;
      min-width: 310px;
      height: max-content;

      &-body {
        padding: var(--space-unit-lg);

        &-show-more {
          max-height: 0px;
          transition: max-height 0.5s ease, opacity 0.5s ease, margin-top 0.5s ease;
          transition-delay: 0s;
          opacity: 0;

          &-animate {
            max-height: 600px;
            opacity: 1;
            margin-top: var(--space-unit-lg);
          }

          &-info {
            border: 1px solid var(--color-grey-greenish);
            border-radius: 10px;
            padding: var(--space-unit-md) var(--space-unit-lg);

            .brand-recomendation-divider {
              margin: var(--space-unit-md) 0;
            }
          }

          &-sales-rep {
            display: flex;
            margin-top: var(--space-unit-lg);

            .brib-sales-rep-icon {
              margin-top: var(--space-unit-xxs);
              margin-right: var(--space-unit-xs);
            }

            .brib-sales-rep-text {
              flex: 1;
              text-align: left;
            }
          }
        }

        &-header {
          display: grid;
          grid-template-columns: 62px 1fr;
          grid-template-rows: 31px 31px;
          align-items: center;
          text-align: left;

          .brand-recommendation-item-logo {
            grid-row-start: 1;
            grid-row-end: 3;
            width: 62px !important;
            height: 62px !important;
            margin: 0 !important;
          }

          .brand-recommendation-item-desc {
            margin-left: var(--space-unit-md);
            grid-column: 2;
          }

          .brand-recommendation-item-bev-name {
            grid-row: 1;
          }

          .brand-recommendation-item-bev-type {
            grid-row: 2;
            color: var(--color-grey-grey100);
          }
        }
      }

      &-show-more {
        display: flex;
        align-items: center;
        justify-content: center;
        border-top: 1px solid var(--color-grey-grey25);
        padding: var(--space-unit-xs) 0;
        width: 100%;
        z-index: 1;
      }
    }
  }
}
