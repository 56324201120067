@import "../../styles/constants.scss";
@import "../../styles/animations.scss";

.settings-page {
  .section {
    min-height: 700px;
    border-bottom: 2px solid black;
    margin: 32px;
  }

  .settings-back-button-container {
    text-align: left;
    margin-bottom: var(--space-unit-lg);
    .settings-back-button {
      padding: 0;
      margin-bottom: var(--space-unit-xxl);
    }
  }

  .tab-list {
    padding: var(--space-unit-xl) 0;
    margin-bottom: var(--space-unit-xxl);

    &-item {
      cursor: pointer;
      text-align: left;
      padding: var(--space-unit-lg) 0;
      border-bottom: 1px solid var(--color-grey-grey25);

      &-title {
        margin-bottom: var(--space-unit-xs);
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      &-desc {
        color: var(--color-grey-grey100);
      }
    }
  }
}
