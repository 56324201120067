@import "../../styles/constants.scss";

.outlet-review {
  .outlet-review-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .outlet-review-info {
      display: flex;
      align-items: center;

      button {
        margin-left: var(--space-unit-xs);
        padding: 0;
      }
    }

    @media (max-width: $mobile-breakpoint-3) {
      padding: 0 var(--space-unit-lg);
    }
    .outlet-review-sort-container {
      display: flex;
      align-items: center;
      font-size: var(--text-ssm);
      @media (max-width: $mobile-breakpoint-3) {
        flex-direction: column;
        align-items: flex-end;
        .sort-span {
          margin-right: var(--space-unit-xs);
        }
      }
    }
  }
  margin-bottom: var(--space-unit-xxxl);

  @media (max-width: $mobile-breakpoint-3) {
    margin-bottom: var(--space-unit-xxl);
  }
}

.outlet-review-container {
  display: flex;
  flex-direction: row;
  margin: var(--space-unit-md) 0 0;
  position: relative;

  .outlet-review-container-scroll {
    flex: 1;
    overflow-y: hidden;
    overflow-x: auto;
    overflow: -moz-scrollbars-none;
    -ms-overflow-style: none;
    display: flex;

    @media (max-width: $mobile-breakpoint-3) {
      padding: 0 var(--space-unit-lg);
    }
    &::-webkit-scrollbar {
      width: 0 !important;
      height: 0 !important;
    }

    // Hide scrollbar in Firefox
    scrollbar-width: none;
  }
  .outlet-review-icon-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
    min-width: 50px;
    cursor: pointer;
    @media (max-width: $mobile-breakpoint-3) {
      display: none;
    }
  }
}

.outlet-review-card-placeholder {
  background-color: var(--color-grey-grey50);
  min-height: 200px;
  min-width: 180px;
  border-radius: var(--border-radius);
  .outlet-placeholder-header {
    display: flex;
    flex-direction: row;
    padding: var(--space-unit-md);
  }
  .outlet-placeholder-row {
    display: flex;
  }
  .outlet-placeholder-column {
    display: flex;
    flex-direction: column;
  }
  .outlet-placeholder {
    background-color: var(--color-white);
    height: 30px;
    margin: 3px;
    position: relative;
    &:after {
      animation: animateLoading 1.5s infinite ease-in-out;
      content: "";
      position: absolute;
      top: 0px;
      width: 100%;
      height: 100%;
      left: -100%;
      opacity: 1;
      background: rgba(255, 255, 255, 0.13);
      background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0.01) 0%,
        rgba(255, 255, 255, 0.13) 77%,
        rgba(255, 255, 255, 0.3) 92%,
        rgba(255, 255, 255, 0) 100%
      );
    }
  }
}
.outlet-review-card {
  min-width: 200px;
  max-height: 212px;
  background-color: var(--color-white);
  padding: var(--space-unit-lg);
  border-radius: var(--border-radius);
  display: flex;
  flex-direction: column;
  border: 1px solid var(--color-grey-greenish);
  cursor: pointer;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  @media (max-width: $mobile-breakpoint-3) {
    max-width: 240px;
  }

  .outlet-review-card-header {
    display: flex;
    margin-bottom: var(--space-unit-lg);
    justify-content: space-between;
    text-overflow: ellipsis;
    white-space: nowrap;

    .outlet-review-name {
      text-align: left;
      line-height: 21.5px;
      overflow: hidden;
      text-overflow: ellipsis;
      overflow-y: auto;
      -ms-overflow-style: none;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        width: 0;
        height: 0;
      }
    }
    .outlet-review-name::-webkit-scrollbar {
      width: 0 !important;
    }
    .outlet-review-total-value {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      margin-left: var(--space-unit-md);
      .outlet-review-total-value-label {
        color: var(--color-grey-grey50);
      }
      .outlet-review-total-value-number {
        white-space: nowrap;
      }
    }
  }
  .outlet-review-card-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;

    .outlet-review-card-content-item {
      padding: var(--space-unit-md) var(--space-unit-xs);
      display: flex;
      flex-direction: column;
      flex: none;
      align-self: stretch;
      background-color: var(--color-white);
      margin: 0 var(--space-unit-sm);
      border-radius: var(--border-radius);
      border: 1px solid var(--color-grey-greenish);
      width: calc(100% - 50% - var(--space-unit-lg));

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }

      .outlet-review-card-item-label {
        color: var(--color-grey-grey100);
        margin-bottom: var(--space-unit-md);
      }

      .outlet-review-card-item-pct {
        margin-bottom: var(--space-unit-xs);
        &.positive {
          color: var(--color-success-success100);
        }

        &.negative {
          color: var(--color-alert-alert100);
        }
      }

      .outlet-review-card-item-value {
        color: var(--color-black);
      }
    }
  }

  &:hover {
    @media (min-width: $mobile-breakpoint-1) {
      border-color: var(--color-black);
    }
  }
}

.circle-carousel-container {
  display: flex;
  justify-content: center;
  margin-bottom: var(--space-unit-lg);
  margin-top: var(--space-unit-xs);

  .circle-carousel {
    width: 70px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    .preview-circle {
      background-color: var(--color-grey-grey50);
      border-radius: 50%;
      width: 6px;
      height: 6px;
      margin: var(--space-unit-xxs);
      &.hidden {
        opacity: 0;
      }
      &.mini {
        width: 4px;
        height: 4px;
      }
      &.highlighted {
        background-color: var(--color-grey-grey100);
      }
    }
  }
}

.outlet-review-card-header-2 {
  display: flex;
  align-items: center;
  line-height: 20px;
  .horizontal-line {
    height: 1px;
    flex: 1;
    background-color: var(--color-grey-grey25);
  }
  .outlet-review-total-value-pct {
    color: var(--color-success-success100);
    margin-left: var(--space-unit-md);
    &.negative {
      color: var(--color-alert-alert100);
    }
  }
}
.outlet-placeholder-lines {
  position: relative;
  flex: 1;
  .outlet-line-current {
    position: absolute;
    bottom: var(--space-unit-lg);
    left: 0px;
    border: 1px solid var(--color-grey-grey50);
    z-index: 1;
    width: 50%;
  }
  .outlet-line-before {
    position: absolute;
    bottom: var(--space-unit-lg);
    left: 0px;
    border: 1px solid #ccc;
    width: 100%;
    border-style: dashed;
  }
}
