@import "../../styles/constants.scss";

.device-item {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: var(--space-unit-lg) 0 var(--space-unit-lg) var(--space-unit-lg);
  @media (max-width: $mobile-breakpoint-2) {
    padding-left: 0;
  }

  .device-item-actions {
    display: flex;
    position: relative;

    .device-item-action {
      margin: 0 calc(var(--space-unit-lg) / 2);

      &:last-child {
        @media (max-width: $mobile-breakpoint-2) {
          margin-right: 0;
          padding-right: 0;
        }
      }
    }

    .device-item-action-divider {
      position: absolute;
      left: 50%;

      @media (max-width: $mobile-breakpoint-2) {
        left: 55%;
      }
    }
  }
}
