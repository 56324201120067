@import "../../../styles/constants.scss";

.insights-button {
  display: flex;
  align-items: center;
  padding: var(--space-unit-md);
  border: 1px solid var(--color-grey-greenish);
  cursor: pointer;
  border-radius: 10px;
  position: relative;
  user-select: none;

  @media (max-width: $mobile-breakpoint-3) {
    padding: var(--space-unit-xxs);
  }

  &.with-insights {
    border-color: var(--color-success-success100);
  }

  &:hover {
    border-color: var(--color-black);
  }

  &:active,
  &:focus {
    background-color: var(--color-grey-grey25);
  }

  .insights-text {
    margin-left: var(--space-unit-md);
  }

  &.insights-button-loading {
    pointer-events: none;
    cursor: unset;

    &::after {
      position: absolute;
      border-radius: 10px;
      content: "";
      background-color: rgba(255, 255, 255, 0.5);
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
    }
  }
}

.insights-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;
  position: relative;

  @media (max-width: $mobile-breakpoint-3) {
    position: initial;
  }

  .insights-number {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    padding: 4px;
    top: -6px;
    right: -6px;
    border-radius: 10px;
    background-color: var(--color-success-success100);
    color: white;
    font-size: 10px;
    line-height: 10px;
  }

  .insights-icon-loading {
    width: 24px;
    height: 24px;

    div {
      box-sizing: border-box;
      display: block;
      position: absolute;
      width: 24px;
      height: 24px;
      border: 4px solid transparent;
      border-radius: 50%;
      animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      border-top-color: var(--color-black);
    }
    div:nth-child(1) {
      animation-delay: -0.45s;
    }
    div:nth-child(2) {
      animation-delay: -0.3s;
    }
    div:nth-child(3) {
      animation-delay: -0.15s;
    }
  }
}

.outlet-item-container .insights-icon {
  @media (max-width: $mobile-breakpoint-3) {
    position: relative;
  }
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.insights-sidebar {
  box-sizing: border-box;
  width: var(--insights-sidebar-width);
  padding: var(--space-unit-xl) var(--space-unit-lg);
  padding-bottom: 0;
  color: var(--color-white);
  background-color: var(--color-background-black);
  position: sticky;
  top: 0;
  height: 100vh;
  display: flex;
  flex-direction: column;

  @media (max-width: $mobile-breakpoint-3) {
    padding: var(--space-unit-lg);
  }

  .insights-sidebar-header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .insights-tabs {
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: auto;
    margin-top: var(--space-unit-xxl);

    &.no-tabs {
      margin-top: 0;
    }

    .insights-tab {
      color: var(--color-white);
    }

    .empty-insight-list {
      color: var(--color-grey-grey100);
      padding: var(--space-unit-lg);
    }

    .insights-tab-container {
      flex: 1;
      overflow-x: hidden;
      overflow-y: auto;
      margin-top: var(--space-unit-lg);
      padding-bottom: var(--space-unit-md);
      overflow: -moz-scrollbars-none;
      -ms-overflow-style: none;

      &::-webkit-scrollbar {
        width: 0 !important;
        height: 0 !important;
      }

      // Hide scrollbar in Firefox
      scrollbar-width: none;
    }
  }

  .insight-timeline-item {
    display: block;
    margin-top: var(--space-unit-xxl);
    color: var(--color-grey-grey100);

    &:nth-child(1) {
      margin-top: var(--space-unit-lg);
    }
  }

  .insight-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
    flex: 1;
    padding: var(--space-unit-md);
    border: 1px solid var(--color-grey-grey100);
    border-radius: 10px;
    margin-top: var(--space-unit-lg);

    @media (max-width: $mobile-breakpoint-3) {
      border: none;
      padding: 0;
    }

    &-base {
      width: 100%;
    }

    &-desc {
      flex: 1;

      &-title {
        color: var(--color-white);
        margin-bottom: var(--space-unit-xs);
      }

      &-content {
        color: var(--color-grey-grey50);
      }
    }

    &.already-seen {
      opacity: 0.7;
    }

    &-outlet-name {
      color: var(--color-grey-grey100);
      margin-left: var(--space-unit-xxxl);
      margin-bottom: var(--space-unit-xs);
    }

    &-info-icon {
      display: flex;

      .ellipsis-icon {
        fill: var(--color-grey-grey100);
        min-width: var(--space-unit-md);

        &:hover {
          fill: var(--color-grey-grey50);
        }
      }
      .menu-ellipsis {
        height: var(--space-unit-lg);
      }
    }

    &-content {
      margin: var(--space-unit-md) 0;
    }
  }
}

.insight-item-content-mobile-divider {
  margin: var(--space-unit-lg) 0;
  width: calc(100% - calc(var(--space-unit-xxl) + var(--space-unit-md))) !important;
  float: right;
}

.insight-item-menu-item {
  background-color: var(--color-background-black) !important;
  border-color: var(--color-background-black) !important;
  cursor: default !important;

  .insight-item-feedback {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;

    &-icon {
      cursor: pointer !important;
      border: 1px solid var(--color-black);
      border-radius: 50%;
      background-color: var(--color-black);
      min-width: var(--space-unit-lg);
      min-height: var(--space-unit-lg);
      fill: var(--color-white);
      margin: 0 var(--space-unit-md);

      &.icon-dislike {
        cursor: initial;
        &:hover {
          border-color: var(--color-alert-alert100);
        }

        &.selected {
          fill: var(--color-alert-alert100);
          border-color: transparent;
        }
      }

      &.icon-like {
        cursor: initial;
        &:hover {
          border-color: var(--color-success-success100);
        }

        &.selected {
          fill: var(--color-success-success100);
          border-color: transparent;
        }
      }
    }
  }
}
