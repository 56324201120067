html {
  height: 100%;
  font-size: 18px;
}
body {
  height: 100%;
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* overscroll-behavior: none; */
}

#root {
  height: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior: none;
}

#modal-root {
  position: absolute;
  top: 0;
  height: 0;
  overflow: visible;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior: none;
}

#modal-root .modal-overlay {
  position: absolute;
  top: 0;
  height: 0px;
  overflow: hidden;
}

#modal-root .modal-overlay.open {
  position: absolute;
  height: 100vh;
  width: 100vw;
  top: 0;
}

.App {
  min-height: 100%;
  height: auto;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
