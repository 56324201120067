@import "../../styles/constants.scss";

.pressurechamber-info-wrapper {
  .keg-summary {
    background-color: var(--color-grey-grey25);
    border: 1px solid var(--color-grey-grey25);
    align-items: center;
    padding: var(--space-unit-lg) var(--space-unit-lg);

    > div:first-child {
      height: 64px;
      width: 64px;
    }

    > div > span {
      font-size: var(--font-size);
      font-weight: 400;
      color: var(--color-black);
    }

    &-title {
      display: flex;
      flex-direction: row;
      margin-bottom: var(--space-unit-xs);

      &--left {
        margin-right: var(--space-unit-md);
      }

      > span {
        font-size: var(--font-size);
        font-weight: 400;
      }
    }

    .keg-label {
      color: var(--color-grey-grey100);
    }

    .keg-brand--initials {
      margin-bottom: 0;
    }
  }
}
