@import "../../styles/constants.scss";

.settings-outlets {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  text-align: left;
  padding: var(--space-unit-xl) 0;

  @media (max-width: $mobile-breakpoint-3) {
    padding-top: 0;
  }

  .settings-outlets-intro {
    margin-bottom: var(--space-unit-md);
  }
  .settings-outlets-text {
    margin-bottom: var(--space-unit-xxl);
  }
}
