@import "../../../styles/constants.scss";

.outlet-picker {
  display: flex;
  cursor: pointer;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;

  .outlet-picker-name {
    margin-right: var(--space-unit-xs);
    text-align: left;
    color: var(--color-black);
  }

  @media (max-width: $mobile-breakpoint-1) {
    justify-content: center;
    margin-right: var(--space-unit-md);
  }
}

.outlet-picker-popover {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-top: 0;
  background-color: var(--color-white);
  padding: 0 var(--space-unit-lg);
  border-radius: 10px;
  border: 1px solid var(--color-grey-greenish);
  box-sizing: border-box;
  max-width: 500px;
  min-width: 500px;
  max-height: 700px;

  @media (max-width: $mobile-breakpoint-3) {
    max-width: calc(100vw - var(--space-unit-xxl));
    max-height: calc(100vh - var(--space-unit-xxl));
    min-width: unset;
    min-height: unset;
  }

  &:focus {
    outline: none;
  }

  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: var(--space-unit-lg) 0;
    border-bottom: 1px solid var(--color-grey-grey50);

    .close-icon {
      cursor: pointer;
    }
  }

  &-search {
    margin: var(--space-unit-lg) 0;

    &:focus {
      outline: none;
    }
  }

  &-tabs {
    display: flex;
    overflow-x: hidden;
    overflow-y: auto;
    padding-bottom: var(--space-unit-md);
    overflow: -moz-scrollbars-none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      width: 0 !important;
      height: 0 !important;
    }

    // Hide scrollbar in Firefox
    scrollbar-width: none;

    &-group {
      margin-bottom: var(--space-unit-lg) !important;
    }

    & > div {
      display: flex;
      flex-direction: column;
      flex: 1;
      width: 100%;

      & > section {
        display: flex;
        overflow-x: hidden;
        overflow-y: auto;
        flex-direction: column;
        max-height: 100%;
        flex: 1;

        overflow: -moz-scrollbars-none;
        -ms-overflow-style: none;
        &::-webkit-scrollbar {
          width: 0 !important;
          height: 0 !important;
        }

        // Hide scrollbar in Firefox
        scrollbar-width: none;
      }
    }
  }

  .outlet-items-panel {
    overflow-y: auto;
  }
  .empty-outlet-list {
    color: var(--color-grey-grey100);
    padding-bottom: var(--space-unit-md);
  }
  .outlet-item-container {
    display: flex;
    flex: 1;
    align-items: center;
    cursor: pointer;
    border: 1px solid var(--color-grey-greenish);
    padding: var(--space-unit-md);
    margin-bottom: var(--space-unit-sm);
    border-radius: 10px;
    padding-right: var(--space-unit-lg);

    &:focus,
    &:hover {
      outline: none;
      border-color: var(--color-grey-grey100);
    }

    &.selected {
      border-width: 2px;
      border-color: var(--color-black);
    }

    .favourite-icon {
      margin-right: var(--space-unit-md);
      fill: var(--color-grey-grey50);
      margin-bottom: 4px; // align icon

      &:hover {
        fill: var(--color-black);
      }

      &.selected {
        fill: var(--color-warning-warning100);
      }
    }

    .outlet-item {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      flex: 1;
    }

    .outlet-new {
      background-color: var(--color-success-success100);
      width: 8px;
      height: 8px;
      border-radius: 50%;
      margin-right: var(--space-unit-xxs);

      &.full-margin {
        margin: var(--space-unit-xs);
      }
    }

    .outlet-new-text {
      color: var(--color-success-success100);
    }

    .outlet-pad {
      width: 8px;
      height: 8px;
      opacity: 0;
      margin-right: var(--space-unit-xxs);
    }
  }

  .outlet-item-all-outlets {
    position: sticky;
    top: 0;
    background-color: var(--color-background-white);
  }

  .outlet-picker-scrollable-content {
    overflow-y: auto;
    padding-top: var(--space-unit-sm);
  }
}

[data-qa="popup-close-button"] {
  display: none;
}
