@import "../../styles/constants.scss";
@import "../../styles/animations.scss";

.pressure-chamber-description {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  margin-top: var(--space-unit-lg);

  &.beerdrive {
    max-width: none;
    padding-left: 0;
  }

  .keg-label {
    color: var(--color-grey-grey100);
  }

  .keg-details {
    display: flex;
    justify-content: center;
    margin-bottom: var(--space-unit-lg);
    position: relative;
    width: 100%;
  }
}
