@import "../../styles/constants.scss";
@import "../../styles/animations.scss";

.product-info-wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: var(--space-unit-xxl);

  @media (max-width: $mobile-breakpoint-3) {
    margin: 0 var(--space-unit-lg) var(--space-unit-xxl) var(--space-unit-lg);
  }

  .product-info {
    display: flex;
    flex: 1 1;
    justify-content: space-between;
    padding: 0;
    padding-right: var(--space-unit-lg);

    @media (max-width: $mobile-breakpoint-1) {
      flex-wrap: wrap;
      padding: var(--space-unit-xs) var(--space-unit-lg) var(--space-unit-xs) 0;
    }

    &-title {
      text-align: start;

      &-wrapper {
        align-items: center;
        display: flex;
        margin-bottom: var(--space-unit-lg);

        > button {
          margin-left: var(--space-unit-xs);
          padding: 0;
        }
      }
    }

    .product-summary-group {
      border: none;
      margin-bottom: 0;

      @media (min-width: $mobile-breakpoint-1) {
        width: auto;
      }

      &-title {
        margin-right: var(--space-unit-lg);
      }
    }

    &-footer {
      padding: var(--space-unit-md);
    }
  }
}
