@import "../../styles/constants.scss";

.outlet-info-card {
  border-bottom: 1px solid var(--color-grey-grey50);

  @media (max-width: $mobile-breakpoint-3) {
    padding: 0;
  }

  .outlet-info-name {
    margin-right: var(--space-unit-xs);
  }

  .outlet-info-status {
    color: var(--color-grey-grey100);
    flex-grow: 1;

    &.incomplete {
      color: var(--color-alert-alert100);
    }
  }

  .outlet-info-options {
    flex: 1;
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    @media (max-width: $mobile-breakpoint-2) {
      flex-wrap: wrap;
    }

    &.outlet-info-option-google {
      max-width: 360px;
    }

    .outlet-info-option {
      flex: 1;
      padding: 0 var(--space-unit-lg) var(--space-unit-lg) var(--space-unit-lg);
      @media (max-width: $mobile-breakpoint-2) {
        padding: 0 0 var(--space-unit-xl) 0;
        max-width: 100%;
        flex: 100%;
      }
    }

    .outlet-info-divider {
      height: auto;
      align-self: stretch;
      margin: 0 var(--space-unit-xs);

      @media (max-width: $mobile-breakpoint-2) {
        display: none;
      }
    }
  }

  .outlet-info-content {
    opacity: 0.15;
    pointer-events: none;
  }

  .outlet-info-content-active {
    opacity: 1;
    pointer-events: initial;
  }

  .outlet-info-radio {
    margin-bottom: var(--space-unit-lg);
  }
}
