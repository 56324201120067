@import "../../../../styles/constants.scss";

@keyframes loadingpc {
  0% {
    margin-left: 0;
  }
  50% {
    margin-left: 100%;
  }
  100% {
    margin-left: 0;
  }
}

.insight-item-base {
  &-brand-confirmation {
    .insight-item-content {
      margin-left: calc(48px + var(--space-unit-md));
    }

    .content-wrapper {
      background-color: var(--color-black);
      border-radius: 10px;
      padding: var(--space-unit-md);
      padding-bottom: 0;
      padding-right: 0;
      display: flex;
      flex-wrap: wrap;

      &.content-wrapper-loading {
        height: calc(var(--space-unit-md) + var(--space-unit-xxl));
        padding: 0;
        overflow: hidden;

        &::after {
          content: "";
          height: 100%;
          width: 0;
          background-color: transparent;
          position: relative;
          box-shadow: 0px 0px 26px 4px;
          animation: loadingpc 5s infinite;
        }
      }

      .beverage-logo {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--color-grey-greenish);
        cursor: pointer;
        height: var(--space-unit-xxl);
        width: var(--space-unit-xxl);
        box-sizing: border-box;
        background-size: cover;
        border-radius: 50%;
        margin-right: var(--space-unit-lg);
        margin-bottom: var(--space-unit-md);

        .beverage-tap-number {
          color: var(--color-white);
          position: absolute;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: var(--color-background-overlay);
        }

        &:hover {
          .beverage-tap-number {
            background-color: rgba(7, 7, 7, 0.2);
          }
        }
      }
    }
  }
  .footer-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
